import React, { useEffect, useState } from 'react';
import {
  ActivityIndicator,
  Image,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { SafeAreaView, SafeAreaProvider } from 'react-native-safe-area-context';
import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import { StatusBar } from 'expo-status-bar';
import * as Linking from 'expo-linking';
import styled from 'styled-components/native';
import FlashMessage, { showMessage } from 'react-native-flash-message';
import { useFonts } from 'expo-font';
import {
  TitilliumWeb_400Regular,
  TitilliumWeb_700Bold,
} from '@expo-google-fonts/titillium-web';
import { Lato_300Light, Lato_400Regular } from '@expo-google-fonts/lato';
import axios from 'axios';

const logo = Asset.fromModule(require('./assets/logo.png'));

axios.defaults.baseURL = __DEV__
  ? 'http://localhost:3000'
  : 'https://spark.golf';

const sparkOrange = '#FB4D2F';

const Container = styled.View`
  flex: 1;
  background-color: #fff;
  padding: 10px;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`;

const Topnav = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  height: 50px;
  width: 200px;
  padding-horizontal: 15px;
`;

const Main = styled.ScrollView`
  padding: 20px 10px;
`;

const Nav = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const NavButton = styled.View`
  width: 20%;
`;

const NavButtonText = styled.Text`
  color: ${sparkOrange};
`;

const Heading = styled.Text`
  color: black;
  font-size: 24px;
  font-family: TitilliumWeb_700Bold;
  text-transform: uppercase;
  margin-vertical: 10px;
`;

const NavHeading = styled(Heading)`
  width: 60%;
  text-align: center;
`;

const Subheading = styled(Heading)`
  font-size: 16px;
`;

const Text = styled.Text`
  font-family: Lato_300Light;
  font-size: 14px;
`;

const RoundDetails = styled.View`
  margin-top: 10px;
`;

const Row = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: #efefef;
  padding-bottom: 10px;
  margin-top: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

const RowLabel = styled.Text`
  font-family: Lato_300Light;
  font-size: 14px;
  width: 25%;
`;

const RowValue = styled.Text`
  font-weight: 600;
  font-family: Lato_400Regular;
  font-size: 14px;
  width: 75%;
`;
const PaymentRowLabel = styled(RowLabel)`
  width: 35%;
`;

const PaymentRowValue = styled(RowValue)`
  width: 65%;
`;

const TransactionRowLabel = styled(RowLabel)`
  width: 80%;
`;

const TransactionRowValue = styled(RowValue)`
  width: 20%;
  text-align: right;
  font-weight: 400;
`;

const ButtonTouchableOpacity = styled.TouchableOpacity`
  background-color: ${sparkOrange};
  padding: 0 16px;
  border-radius: 2px;
  align-items: center;
  align-self: center;
  margin: 20px 10px;
  max-width: 320px;
  width: 100%;
`;

const ButtonText = styled.Text`
  color: white;
  font-family: TitilliumWeb_700Bold;
  line-height: 40px;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

const ConfirmationChoices = styled.View`
  margin-top: 20px;
`;

const Choice = styled.View`
  border-bottom-width: 1px;
  border-bottom-color: #efefef;
  padding-bottom: 10px;
  margin-top: 10px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const ChoiceLabel = styled.Text`
  font-weight: 700;
  font-family: TitilliumWeb_700Bold;
  font-size: 12px;
  text-transform: uppercase;
`;

const RadioButtonBorder = styled.View`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border-width: 1px;
  margin-right: 10px;
  border-color: ${sparkOrange};
  align-items: center;
  justify-content: center;
`;

const RadioButtonSelected = styled.View`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background-color: ${sparkOrange};
`;

const PaymentInfo = styled.View`
  margin-vertical: 10px;
`;

const Players = styled.View`
  margin-top: 20px;
`;

const PlayerLabel = styled.Text`
  font-family: Lato_300Light;
  font-size: 14px;
  width: 60px;
`;

const PlayerValue = styled.Text`
  font-weight: 400;
  font-family: Lato_400Regular;
  font-size: 14px;
  width: 80%;
`;

const MemberLabel = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  margin-horizontal: 6px;
  padding-horizontal: 4px;
  background-color: ${sparkOrange};
  border-radius: 2px;
  height: 24px;
  line-height: 24px;
`;

const MemberText = styled.View`
  color: white;
  font-weight: bold;
  font-size: 12px;
`;

function RadioButton(props) {
  return (
    <RadioButtonBorder>
      {props.value === props.selectedValue ? <RadioButtonSelected /> : null}
    </RadioButtonBorder>
  );
}

function Button({ onPress, children }) {
  return (
    <ButtonTouchableOpacity onPress={onPress}>
      <ButtonText>{children}</ButtonText>
    </ButtonTouchableOpacity>
  );
}

function Layout({ children }) {
  return (
    <>
      <SafeAreaProvider>
        <SafeAreaView style={{ flex: 1, backgroundColor: '#fff' }}>
          <Container>
            <Topnav>
              <Image style={{ width: 200, height: 50 }} source={logo} />
            </Topnav>
            <Main>
              {children}
              <StatusBar style='auto' />
            </Main>
          </Container>
        </SafeAreaView>
      </SafeAreaProvider>
      <FlashMessage position='bottom' />
    </>
  );
}

function renderPlayers(round, setShowChoices) {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const {
    amountToCharge,
    creditCard,
    transactions,
    registrationClosed,
    playerCount,
    maxNumberOfPlayers,
    teams,
  } = round;

  return (
    <>
      {!registrationClosed && (
        <Button onPress={() => setShowChoices(true)}>
          Change Start Hole / Course
        </Button>
      )}

      {amountToCharge && creditCard && (
        <PaymentInfo>
          <Subheading>
            Amount To Charge: {currencyFormatter.format(amountToCharge)}
          </Subheading>

          <Row>
            <PaymentRowLabel>Brand</PaymentRowLabel>
            <PaymentRowValue>{creditCard.brand}</PaymentRowValue>
          </Row>

          <Row>
            <PaymentRowLabel>Number</PaymentRowLabel>
            <PaymentRowValue>{creditCard.number}</PaymentRowValue>
          </Row>

          <Row>
            <PaymentRowLabel>Cardholder Name</PaymentRowLabel>
            <PaymentRowValue>Spark Golf</PaymentRowValue>
          </Row>

          <Row>
            <PaymentRowLabel>Expires</PaymentRowLabel>
            <PaymentRowValue>
              {creditCard.expMonth}/{creditCard.expYear}
            </PaymentRowValue>
          </Row>

          <Row>
            <PaymentRowLabel>Security Code</PaymentRowLabel>
            <PaymentRowValue>{creditCard.cvc}</PaymentRowValue>
          </Row>

          <Row>
            <PaymentRowLabel>Billing Zip</PaymentRowLabel>
            <PaymentRowValue>32804</PaymentRowValue>
          </Row>

          <Subheading style={{ marginTop: 20 }}>
            Account Transactions
          </Subheading>

          {transactions.map((transaction, i) => {
            return (
              <Row key={transaction.id}>
                <TransactionRowLabel
                  style={{
                    fontWeight: i == transactions.length - 1 ? 600 : 400,
                  }}
                >
                  {transaction.description}
                </TransactionRowLabel>
                <TransactionRowValue
                  style={{
                    fontWeight: i == transactions.length - 1 ? 600 : 400,
                  }}
                >
                  {currencyFormatter.format(transaction.amount)}
                </TransactionRowValue>
              </Row>
            );
          })}
        </PaymentInfo>
      )}

      <Players>
        <Subheading>
          {playerCount} of {maxNumberOfPlayers} Players Registered
        </Subheading>

        {registrationClosed && (
          <Text
            style={{ marginVertical: 16, color: sparkOrange }}
            onPress={() => {
              const url = `https://spark.golf/leaderboards/${round.id}`;
              if (Platform.OS == 'web') {
                window.open(url, '_blank');
              } else {
                Linking.openURL(url);
              }
            }}
          >
            View Leaderboard
          </Text>
        )}

        {teams.map((team) => {
          if (registrationClosed) {
            return (
              <Row key={team.id}>
                <PlayerLabel>{team.startLabel}</PlayerLabel>
                <PlayerValue>
                  {team.players.map((p, i) => {
                    return (
                      <View
                        key={p.id}
                        style={{
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          lineHeight: 26,
                        }}
                      >
                        {i ? <Text>&nbsp;/&nbsp;</Text> : null}
                        <Text>{p.name}</Text>
                        {p.member && (
                          <MemberLabel>
                            <MemberText>{p.member}</MemberText>
                          </MemberLabel>
                        )}
                      </View>
                    );
                  })}
                </PlayerValue>
              </Row>
            );
          } else {
            return (
              <Row key={team.id}>
                <Text>{team.name}</Text>
              </Row>
            );
          }
        })}
      </Players>
    </>
  );
}

function renderChoices(
  round,
  confirmationChoice,
  setConfirmationChoice,
  confirm
) {
  if (!round.canBeConfirmed) {
    return <></>;
  }

  const choices = [];

  if (round.startHoles.length == 1) {
    choices.push({
      value: `${round.tee.id}-${round.startHoleNumber}`,
      label: `Yes, we are playing ${round.course.name}`,
    });
  } else {
    choices.push({
      value: `${round.tee.id}-${round.startHoleNumber}`,
      label: `Yes, we are playing the ${
        round.startHoleNumber < 10 ? 'front' : 'back'
      } nine`,
    });
  }

  if (round.startHoles.length > 1) {
    if (round.startHoleNumber == 1) {
      choices.push({
        value: `${round.tee.id}-10`,
        label: 'No, we are playing the back nine',
      });
    } else {
      choices.push({
        value: `${round.tee.id}-1`,
        label: 'No, we are playing the front nine',
      });
    }
  }

  for (let course of round.alternateCourses) {
    for (let startHole of course.startHoles) {
      if (round.startHoles.length == 1) {
        choices.push({
          value: `${course.teeId}-${startHole}`,
          label: `No, we are playing ${course.name}`,
        });
      } else {
        choices.push({
          value: `${course.teeId}-${startHole}`,
          label: `No, we are playing the ${
            startHole < 10 ? 'front' : 'back'
          } nine of ${course.name}`,
        });
      }
    }
  }

  choices.push({
    value: '0',
    label: 'No, please have someone call me to discuss',
  });

  return (
    <ConfirmationChoices>
      <Subheading>Is This Correct?</Subheading>

      {choices.map((choice) => {
        return (
          <TouchableWithoutFeedback
            key={choice.value}
            onPress={() => setConfirmationChoice(choice.value)}
          >
            <Choice>
              <RadioButton
                value={choice.value}
                selectedValue={confirmationChoice}
              />
              <ChoiceLabel>{choice.label}</ChoiceLabel>
            </Choice>
          </TouchableWithoutFeedback>
        );
      })}

      <Button onPress={confirm}>Submit</Button>
    </ConfirmationChoices>
  );
}

export default () => {
  let [fontsLoaded] = useFonts({
    TitilliumWeb_400Regular,
    TitilliumWeb_700Bold,
    Lato_300Light,
    Lato_400Regular,
  });

  const [token, setToken] = useState(null);
  const [roundId, setRoundId] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showChoices, setShowChoices] = useState(null);
  const [confirmationChoice, setConfirmationChoice] = useState(null);

  useEffect(() => {
    const getToken = async () => {
      const initialURL = await Linking.getInitialURL();
      const url = new URL(initialURL);
      const token = url.pathname.split('/')[1];
      if (token) {
        setToken(token);
      } else {
        setError('Invalid Link');
      }
    };

    getToken();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        let url = `/api/course_operator/${token}`;
        if (roundId) {
          url += `?round_id=${roundId}`;
        }
        const { data, error } = await axios.get(url);
        setData(data);
        setError(error);
        setConfirmationChoice(
          data.round.callRequested
            ? '0'
            : `${data.round.tee.id}-${data.round.startHoleNumber}`
        );
        setShowChoices(!data.round.confirmed);
        setLoading(false);
      } catch (e) {
        setError(e.message);
      }
    };

    if (token) {
      getData();
    }
  }, [token, roundId]);

  function navigateToRound(roundId) {
    setLoading(true);
    setRoundId(roundId);
  }

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  if (error) {
    return (
      <Layout>
        <Heading>Application Error</Heading>
        <Text>{error}</Text>
      </Layout>
    );
  }

  if (!token) {
    return <AppLoading />;
  }

  if (loading || !data || showChoices === null) {
    return (
      <Layout>
        <ActivityIndicator
          style={{ marginTop: 150 }}
          size='large'
          color={sparkOrange}
        />
      </Layout>
    );
  }

  const { round } = data;

  async function confirm() {
    try {
      setLoading(true);
      const { data, error } = await axios.post(
        `/api/course_operator/${token}`,
        {
          choice: confirmationChoice,
          round_id: round.id,
        }
      );
      setError(error);
      setData(data);
      setShowChoices(!data.round.confirmed);
      setLoading(false);
      showMessage({
        message: data.round.confirmed
          ? `Round ${data.round.number} is now confirmed`
          : 'We have received your request for a call. Someone will reach out to you shortly.',
        type: 'success',
        icon: 'auto',
      });
    } catch (e) {
      setLoading(false);
      showMessage({
        message: e.message || 'An error has occurred',
        type: 'danger',
        icon: 'auto',
        autoHide: false,
      });
    }
  }

  return (
    <Layout>
      <Nav>
        <NavButton>
          {round.previousRoundId && (
            <TouchableOpacity
              onPress={() => navigateToRound(round.previousRoundId)}
            >
              <NavButtonText>&lt; Prev</NavButtonText>
            </TouchableOpacity>
          )}
        </NavButton>
        <NavHeading>Round {round.number}</NavHeading>
        <NavButton>
          {round.nextRoundId && (
            <TouchableOpacity
              onPress={() => navigateToRound(round.nextRoundId)}
            >
              <NavButtonText>Next &gt;</NavButtonText>
            </TouchableOpacity>
          )}
        </NavButton>
      </Nav>

      <RoundDetails>
        <Row>
          <RowLabel>League</RowLabel>
          <RowValue>{round.league.name}</RowValue>
        </Row>

        <Row>
          <RowLabel>Day</RowLabel>
          <RowValue>{round.startDay}</RowValue>
        </Row>

        <Row>
          <RowLabel>Start Time</RowLabel>
          <RowValue>{round.startTime}</RowValue>
        </Row>

        <Row>
          <RowLabel>Start Type</RowLabel>
          <RowValue>{round.startType}</RowValue>
        </Row>

        <Row>
          <RowLabel>Course</RowLabel>
          <RowValue>{round.course.name}</RowValue>
        </Row>

        <Row>
          <RowLabel>Start Hole</RowLabel>
          <RowValue>{round.startHoleNumber}</RowValue>
        </Row>

        <Row>
          <RowLabel>Status</RowLabel>
          <RowValue style={{ color: round.statusActive ? 'green' : 'red' }}>
            {round.status}
          </RowValue>
        </Row>

        {round.registrationClosed && (
          <Row>
            <RowLabel>Deadline</RowLabel>
            <RowValue>{round.registrationClosesAt}</RowValue>
          </Row>
        )}
      </RoundDetails>

      {showChoices
        ? renderChoices(
            round,
            confirmationChoice,
            setConfirmationChoice,
            confirm
          )
        : renderPlayers(round, setShowChoices)}
    </Layout>
  );
};
